import Logo from '@components/common/logo'
import { useSessionContext } from '@contexts/session'
import useResolution from '@hooks/useResolution'
import { FLAGGED_FEATURES } from '@lib/utils/feature-flag'
import { t } from '@lingui/macro'
import { useFeature } from 'flagged'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useMemo, useState } from 'react'
import { Menu } from 'react-feather'

const DesktopNavbar = dynamic(() =>
  import('@components/common/navbar/desktop-navbar')
)
const MobileNavbar = dynamic(() =>
  import('@components/common/navbar/mobile-navbar')
)

const defaultProps = {
  loggedIn: false,
  showPartnerLink: false,
  isDark: false,
}

type Props = {
  isDark: boolean
  showPartnerLink?: boolean
  next?: string
  isSticky?: boolean
  customCTAText?: string
  customCTAonClick?: () => void
} & typeof defaultProps

// TODO: Complete i18n support
const NavBar = ({
  isDark,
  showPartnerLink,
  next,
  customCTAText,
  customCTAonClick,
  isSticky = false,
}: Props) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const {
    isPaidUser,
    username,
    loggedIn,
    name,
    teacher,
    timezone,
  } = useSessionContext()
  const { sizes } = useResolution()

  const isDesktop = useMemo(() => sizes.lg || sizes.xl || sizes['2xl'], [sizes])
  const isQuizChampionship = useFeature(FLAGGED_FEATURES.QUIZ_COMPETITION.flag)

  const bgColor = isDark ? 'bg-orange' : 'bg-white'
  const trialClassCompleteLink = '/trial-class/complete'

  const getFormattedName = (name) => {
    // Extract the title if it exists and the rest of the name
    const titleMatch = name.match(/^(Mr|Mrs|Ms|Miss|Dr)\./)
    const title = titleMatch ? titleMatch[0].trim().replace('.', '') : null
    const normalized = title
      ? name.replace(/^(Mr|Mrs|Ms|Miss|Dr)\.\s*/, '')
      : name
    const parts = normalized.split(' ')

    // Return the name with the title if one was extracted, otherwise just the first part
    return title ? `${title}. ${parts[0]}` : parts[0]
  }
  const firstName = name ? getFormattedName(name) : ''

  return (
    <nav
      className={`${
        isSticky && 'sticky top-0'
      } flex h-16 z-30 shadow ${bgColor}`}
    >
      <div className="z-20 flex items-center justify-between w-full px-5">
        <div className="flex justify-between w-full md:hidden">
          <Link href={loggedIn ? '/dashboard/' : '/'}>
            <a className="flex items-center">
              <Logo
                white={isDark}
                withText
                className="cursor-pointer mr-20"
                style={{ width: '120px', height: '32px' }}
                alt={t`Codingal`}
                height={32}
                width={120}
              />
            </a>
          </Link>
          <button
            onClick={() => {
              document.body.classList.add(
                'overflow-y-hidden',
                'md:overflow-y-auto'
              )
              setShowMobileMenu(true)
            }}
            className={`md:hidden focus:outline-none transform transition duration-500 ease-out ${
              showMobileMenu ? 'scale-0' : 'scale-100'
            }`}
            aria-label="Mobile Nav Bar"
          >
            <Menu className="text-grey" />
          </button>
        </div>
        {isDesktop && (
          <DesktopNavbar
            customCTAText={customCTAText}
            customCTAonClick={customCTAonClick}
            isPaidUser={isPaidUser}
            isQuizChampionship={isQuizChampionship}
            loggedIn={loggedIn}
            next={next}
            trialClassCompleteLink={trialClassCompleteLink}
            username={username}
            firstName={firstName}
            isDark={isDark}
            isTeacher={Boolean(teacher)}
            showPartnerLink={showPartnerLink}
            timezone={timezone}
          />
        )}
        {!isDesktop && (
          <MobileNavbar
            customCTAText={customCTAText}
            customCTAonClick={customCTAonClick}
            isPaidUser={isPaidUser}
            isQuizChampionship={isQuizChampionship}
            loggedIn={loggedIn}
            next={next}
            setShowMobileMenu={setShowMobileMenu}
            showMobileMenu={showMobileMenu}
            trialClassCompleteLink={trialClassCompleteLink}
            username={username}
            teacher={teacher}
          />
        )}
      </div>
    </nav>
  )
}

NavBar.defaultProps = defaultProps
export default NavBar
