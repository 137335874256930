import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { defineMessage, Trans } from '@lingui/macro'
import Link from 'next/link'

export type FaqContentType = {
  questionName: LinguiJSMessageDescriptor
  acceptedAnswerText?: LinguiJSMessageDescriptor | JSX.Element
  acceptedAnswerTextString: LinguiJSMessageDescriptor
}
export const FAQ_CONTENTS: FaqContentType[] = [
  {
    questionName: defineMessage({
      message: `Why should kids and teens learn to code?`,
    }),
    acceptedAnswerText: (
      <>
        <Link href="/coding-for-kids">
          <a className="text-orange mr-1 hover:underline">
            <Trans id="homepage.faq.answer1.linkText">
              Coding for kids and teens{' '}
            </Trans>
          </a>
        </Link>
        <Trans id="homepage.faq.answer1">
          helps improve their logical thinking, critical thinking,
          problem-solving skills, creativity, mathematics and writing skills and
          also gives them valuable skills in life and at the workplace. Coding
          provides a competitive advantage when applying to colleges,
          internships, and jobs. In addition, basic programming knowledge can
          change how kids and teens interact daily with the technologies they
          use. It’s a basic literacy–one we can’t afford to overlook.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Coding for kids and teens helps improve their logical thinking, critical thinking, problem-solving skills, creativity, mathematics and writing skills and also gives them valuable skills in life and at the workplace. Coding provides a competitive advantage when applying to colleges, internships, and jobs. In addition, basic programming knowledge can change how kids and teens interact daily with the technologies they use. It’s a basic literacy–one we can’t afford to overlook.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `My child is a beginner, is previous coding experience required?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          Our coding courses for kids and teens have been developed so that a
          complete beginner can follow along without any trouble. With
          technology increasingly prevalent across so many industries, even
          basic coding knowledge can help kids and teens regardless of the
          career they ultimately decide to pursue.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Our coding courses for kids and teens have been developed so that a complete beginner can follow along without any trouble. With technology increasingly prevalent across so many industries, even basic coding knowledge can help kids and teens regardless of the career they ultimately decide to pursue.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What is the recommended age for my child to start coding?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          Kids can start learning to code as early as 5-6 years old. When kids
          learn to code, it’s typically done under the guidance of a game. This
          makes the process fun and engaging while the kids are still learning a
          new skill. Our highly qualified Computer Science teachers at Codingal
          break the complex concepts into small digestible chunks for your
          child. Kids who learn to code when they’re young can set themselves up
          for a lifetime of opportunities for success.
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `Kids can start learning to code as early as 5-6 years old. When kids learn to code, it’s typically done under the guidance of a game. This makes the process fun and engaging while the kids are still learning a new skill. Our highly qualified Computer Science teachers at Codingal break the complex concepts into small digestible chunks for your child. Kids who learn to code when they’re young can set themselves up for a lifetime of opportunities for success.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What courses does Codingal offer?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          Codingal currently offers live online coding classes for kids and
          teens. Our coding courses include Scratch Programming, Python for kids
          and teens, App Development, Web Development, Game Development, Data
          Science for kids, Robotics and many other courses for Grade 1-12
          students from age 5 years to age 17 years.
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `Codingal currently offers live online coding classes for kids and teens. Our coding courses include Scratch Programming, Python for kids and teens, App Development, Web Development, Game Development, Data Science for kids, Robotics and many other courses for Grade 1-12 students from age 5 years to age 17 years.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `How will I stay updated on my child’s progress?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          A key aspect of our coding courses for kids and teens is keeping
          parents informed on what happens during the online coding class. We
          conduct the first Parent Teacher Meeting(PTM) after the 6th session,
          and the second meeting is conducted after the 12th session. As the
          course progresses, the PTM happens after every 12th session. Parents
          receive progress reports after every Parent Teacher Meeting that
          indicate what students have done during their classes and have links
          to their projects. Besides regular email updates on the kid&apos;s
          progress, parents and teachers are welcome to audit a current class or
          review recorded sessions of completed classes.
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `A key aspect of our coding courses for kids and teens is keeping parents informed on what happens during the online coding class. We conduct the first Parent Teacher Meeting(PTM) after the 6th session, and the second meeting is conducted after the 12th session. As the course progresses, the PTM happens after every 12th session. Parents receive progress reports after every Parent Teacher Meeting that indicate what students have done during their classes and have links to their projects. Besides regular email updates on the kid's progress, parents and teachers are welcome to audit a current class or review recorded sessions of completed classes.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `How does Codingal select the best Coding Teachers?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          Codingal’s teachers are experts in computer science, backed with years
          of industry and teaching experience, and passionate about teaching
          kids and teens. We hire only the top 1% of applicants who are vetted
          for their technical skills and communication skills. A thorough
          background check is also done. We train our teachers to help them
          continue improving their skills with continuous teacher observation
          and feedback. Our teachers are best-in-class who are on a mission to
          inspire kids to fall in love with coding.
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `Codingal’s teachers are experts in computer science, backed with years of industry and teaching experience, and passionate about teaching kids and teens. We hire only the top 1% of applicants who are vetted for their technical skills and communication skills. A thorough background check is also done. We train our teachers to help them continue improving their skills with continuous teacher observation and feedback. Our teachers are best-in-class who are on a mission to inspire kids to fall in love with coding.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `How do I teach coding to my child?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          Coding is a skill that requires specialized knowledge. While there are
          many resources available online that can help you catch up on the
          basics and start teaching coding to your child. However, the best and
          most effective way to teach a child to code is by ensuring they have a
          qualified and trained computer science teacher to guide them on their
          coding journey. Try a free 1-on-1{' '}
          <a
            href="https://www.codingal.com/"
            target="blank"
            rel="noreferrer"
            className="text-blue-200 underline"
          >
            coding class for kids and teens
          </a>{' '}
          to get started.
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `Coding is a skill that requires specialized knowledge. While there are many resources available online that can help you catch up on the basics and start teaching coding to your child. However,  the best and most effective way to teach a child to code is by ensuring they have a qualified and trained computer science teacher to guide them on their coding journey. Try a free coding class for kids and teens to get started.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Can my child learn coding for free?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          There is no shortage of websites and platforms that offer free access
          to resources to teach coding to kids and teens. However, every child
          learns differently and at a different pace, and guided learning is a
          far more effective method for teaching kids to code. Coding is a
          specialized subject and Coding instructors with Computer Science
          background are best suited to teach it in a live format. Your child
          will develop interest in coding with right guidance from the teachers.
          You can try a free class with Codingal to experience the magical world
          of coding.
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `There is no shortage of websites and platforms that offer free access to resources to teach coding to kids and teens. However, every child learns differently and at a different pace, and guided learning is a far more effective method for teaching kids to code. Coding is a specialized subject and Coding instructors with Computer Science background are best suited to teach it in a live format. Your child will develop interest in coding with right guidance from the teachers. You can try a free class with Codingal to experience the magical world of coding.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Are there any games to teach coding to kids?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          There are quite a few games that kids can learn coding from, like
          Minecraft, Code Monster, RoboZZle, Blockly Games, etc. are some of the
          popular ones.
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `There are quite a few games that kids can learn coding from, like Minecraft, Code Monster, RoboZZle, Blockly Games, etc. are some of the popular ones.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What are the best apps for kids to learn coding?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          Many apps make coding fun and interactive for young children.
          Codingal, Scratch, Blockly, MIT App Inventor are suitable for those
          just starting. Intermediate coders who want to build an app or website
          can check out Codingal, CodeCombat, Stencyl, and Code.org. Advanced
          coders can also participate in coding competitions and hackathons
          organized by IOI, Codeforces, and Codingal.
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `Many apps make coding fun and interactive for young children. Codingal, Scratch, Blockly, MIT App Inventor are suitable for those just starting. Intermediate coders who want to build an app or website can check out Codingal, CodeCombat, Stencyl, and Code.org. Advanced coders can also participate in coding competitions and hackathons organized by IOI, Codeforces, and Codingal.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Is the coding course schedule flexible?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          Yes! You can work the classes around your child’s schedule by
          selecting any time and any day that works for you.
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `Yes! You can work the classes around your child’s schedule by selecting any time and any day that works for you.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What kind of device does my child need to start learning to code?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          All that is needed to learn coding with Codingal is a laptop/computer
          with a webcam and a stable internet connection. Classes can also be
          attended on a tablet/iPad with an external keyboard and working mic
          and camera.
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `All that is needed to learn coding with Codingal is a laptop/computer with a webcam and a stable internet connection. Classes can also be attended on a tablet/iPad with an external keyboard and working mic and camera. `,
    }),
  },
  {
    questionName: defineMessage({
      message: `How can I trust Codingal?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          Codingal has been founded by leading edtech entrepreneurs, IIT
          graduates, Google & Amazon alumni and also backed by leading investors
          like Y Combinator and Rebright Partners. You can know more about us
          here - https://www.codingal.com/about-us/. Further, we provide money
          back guarantee if you decide to discontinue after enrollment due to
          any reason.
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `Codingal has been founded by leading edtech entrepreneurs, IIT graduates, Google & Amazon alumni and also backed by leading investors like Y Combinator and Rebright Partners. You can know more about us here - https://www.codingal.com/about-us/. Further, we provide money back guarantee if you decide to discontinue after enrollment due to any reason.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What is the origin of the name “Codingal” ?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          Codingal name had the origin from “coding” + “magical”. Learning
          coding is a magical experience for the kids and hence, the name became
          “Codingal”.
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `Codingal name had the origin from “coding” + “magical”. Learning coding is a magical experience for the kids and hence, the name became “Codingal”.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Why does Codingal have Penguin as logo and mascot?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans>
          Penguins don&apos;t just look smart, they are highly intelligent, says
          study. Penguins are known for looking sharp. They have long been
          adored for their waddling gait and striking black and white attire
          that gives them the appearance of a flock of dinner jackets. Because
          Coding gives superpower, it makes everyone learning coding smarter and
          more intelligent in today&apos;s world of technology. We chose
          “Penguin” as our logo. We also call our penguin dearly with the name
          Cody!
        </Trans>
      </>
    ),

    acceptedAnswerTextString: defineMessage({
      message: `Penguins don't just look smart, they are highly intelligent, says study. Penguins are known for looking sharp. They have long been adored for their waddling gait and striking black and white attire that gives them the appearance of a flock of dinner jackets. Because Coding gives superpower, it makes everyone learning coding smarter and more intelligent in today's world of technology. We chose “Penguin” as our logo. We also call our penguin dearly with the name Cody!`,
    }),
  },
]

export const QUIZZES_FAQ_CONTENTS: FaqContentType[] = [
  {
    questionName: defineMessage({
      message: `Why should kids learn to code?`,
    }),
    acceptedAnswerText: (
      <>
        <Link href="/coding-for-kids">
          <a className="text-orange mr-1 hover:underline">
            <Trans id="quizzes.faq.answer1.linkText">
              Coding for kids and teens{' '}
            </Trans>
          </a>
        </Link>
        <Trans id="quizzes.faq.answer1">
          helps creativity, communication, self-discipline, and many other
          necessary traits. Coding quizzes are individually motivated
          activities, making it a fun and effective way to teach children early
          on how to probe their knowledge about Computer Science and various
          programming languages.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Coding for kids and teens helps creativity, communication, self-discipline, and many other necessary traits. Coding quizzes are individually motivated activities, making it a fun and effective way to teach children early on how to probe their knowledge about Computer Science and various programming languages.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What are the benefits of coding quizzes for kids and teens?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="quizzes.faq.answer2">
          Coding quizzes for kids and teens can be an exceptionally effective
          way to learn. Taking quizzes can produce better recall of facts,
          motivate kids, and help them assess their knowledge.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Coding quizzes for kids and teens can be an exceptionally effective way to learn. Taking quizzes can produce better recall of facts, motivate kids, and help them assess their knowledge.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Are coding quizzes effective in learning?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="quizzes.faq.answer3">
          Coding quizzes are fun activities for kids and teens. They are also a
          sneaky form of learning as they don&apos;t feel like a traditional
          activity. Quizzes can help kids practice existing Computer Science
          knowledge while stimulating interest in learning about new coding
          topics.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Coding quizzes are fun activities for kids and teens. They are also a sneaky form of learning as they don't feel like a traditional activity. Quizzes can help kids practice existing Computer Science knowledge while stimulating interest in learning about new coding topics.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Are coding quizzes good for my child’s brain development?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="quizzes.faq.answer4">
          Yes, coding quizzes are good for a child&apos;s brain development. An
          interactive coding quiz is fun, helps knowledge retention, and expands
          your kid&apos;s brain capacity.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Yes, coding quizzes are good for a child's brain development. An interactive coding quiz is fun, helps knowledge retention, and expands your kid's brain capacity.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Is there any negative marking for wrong answers?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="quizzes.faq.answer5">
          No, there is no negative marking for wrong answers. In case of late
          submission, if the solution submitted is wrong, no points will be
          given and half points will be given in case of the correct answer.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `No, there is no negative marking for wrong answers. In case of late submission, if the solution submitted is wrong, no points will be given and half points will be given in case of the correct answer.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Is there a time limit while attempting the quiz?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="quizzes.faq.answer6">
          Every problem in the quiz is given a certain time limit depending on
          the question asked.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Every problem in the quiz is given a certain time limit depending on the question asked.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `How many times can I attempt a quiz?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="quizzes.faq.answer7">
          You can attempt a quiz any number of times. There is no limit on
          attempting quizzes. After a quiz is completed, a play again option
          will be available on this page.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `You can attempt a quiz any number of times. There is no limit on attempting quizzes. After a quiz is completed, a play again option will be available on this page.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `How do I teach coding to my child?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="quizzes.faq.answer8">
          Coding is a skill that requires specialized knowledge. While there are
          many resources available online that can help you catch up on the
          basics and start teaching coding to your child. However, the best and
          most effective way to teach a child to code is by ensuring they have a
          qualified and trained computer science teacher to guide them on their
          coding journey. Try a free 1-on-1 coding class for kids to get
          started.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Coding is a skill that requires specialized knowledge. While there are many resources available online that can help you catch up on the basics and start teaching coding to your child. However, the best and most effective way to teach a child to code is by ensuring they have a qualified and trained computer science teacher to guide them on their coding journey. Try a free coding class for kids to get started.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Are there any games to teach coding to kids?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="quizzes.faq.answer9">
          There are quite a few games that kids can learn coding from, like
          Minecraft, Code Monster, RoboZZle, Blockly Games, etc. are some of the
          popular ones.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `There are quite a few games that kids can learn coding from, like Minecraft, Code Monster, RoboZZle, Blockly Games, etc. are some of the popular ones.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What are the best apps for kids to learn coding?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="quizzes.faq.answer10">
          Many apps make coding fun and interactive for young children.
          Codingal, Scratch, Blockly, MIT App Inventor are suitable for those
          just starting. Intermediate coders who want to build an app or website
          can check out Codingal, CodeCombat, Stencyl, and Code.org. Advanced
          coders can also participate in coding competitions and hackathons
          organized by IOI, Codeforces, and Codingal.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Many apps make coding fun and interactive for young children. Codingal, Scratch, Blockly, MIT App Inventor are suitable for those just starting. Intermediate coders who want to build an app or website can check out Codingal, CodeCombat, Stencyl, and Code.org. Advanced coders can also participate in coding competitions and hackathons organized by IOI, Codeforces, and Codingal.`,
    }),
  },
]

export const CODINGAL_VS_WHITEHATJS_FAQ: FaqContentType[] = [
  {
    questionName: defineMessage({
      message: `What is the biggest difference between Codingal’s online coding classes and Whitehat Jr. classes?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="codingalVsWhitehatJr.faq.answer1">
          All our coding classes are conducted by expert Computer Science
          teachers, unlike Whitehat Jr, where teachers are from varied
          backgrounds. Our curriculum is based on BIDE, STEAM, and Bloom’s
          taxonomy to develop 8 core competencies in your child. In addition,
          the students get a STEM.org accredited certificate on successfully
          completing a free trial class.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `All our coding classes are conducted by expert Computer Science teachers, unlike Whitehat Jr, where teachers are from varied backgrounds. Our curriculum is based on BIDE, STEAM, and Bloom’s taxonomy to develop 8 core competencies in your child. In addition, the students get a STEM.org accredited certificate on successfully completing a free trial class.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Are coding classes by Codingal better?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="codingalVsWhitehatJr.faq.answer2">
          Codingal’s mission is to help your child fall in love with coding. We
          believe that success will surely follow if a child enjoys coding and
          falls in love with it. We don’t believe in creating a stressful
          experience for you and your child by scaring you into buying our
          product. And most importantly, we won’t hound you with spam calls and
          harass you into buying our product.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Codingal’s mission is to help your child fall in love with coding. We believe that success will surely follow if a child enjoys coding and falls in love with it. We don’t believe in creating a stressful experience for you and your child by scaring you into buying our product. And most importantly, we won’t hound you with spam calls and harass you into buying our product.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Will my child get access to free resources if I enrol my child in Codingal?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="codingalVsWhitehatJr.faq.answer3">
          Yes. Your child will get lifetime access to all the learning
          resources, coding competitions, quizzes, and more.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Yes. Your child will get lifetime access to all the learning resources, coding competitions, quizzes, and more.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Will my child learn coding only from experts at Codingal?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="codingalVsWhitehatJr.faq.answer4">
          Yes. Codingal’s teachers are experts in computer science, backed with
          years of industry and teaching experience, and passionate about
          teaching kids. We hire only the top 1% of applicants who are vetted
          for their technical skills and communication skills. A thorough
          background check is also done. We train our teachers to help them
          continue improving their skills with continuous teacher observation
          and feedback. Our teachers are best-in-class who are on a mission to
          inspire kids to fall in love with coding.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Yes. Codingal’s teachers are experts in computer science, backed with years of industry and teaching experience, and passionate about teaching kids. We hire only the top 1% of applicants who are vetted for their technical skills and communication skills. A thorough background check is also done. We train our teachers to help them continue improving their skills with continuous teacher observation and feedback. Our teachers are best-in-class who are on a mission to inspire kids to fall in love with coding.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `Why does Codingal offer more value for the price than Whitehat Jr?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="codingalVsWhitehatJr.faq.answer5">
          Our{' '}
          <a
            href="/coding-for-kids"
            className="text-orange mr-1 hover:underline"
          >
            Coding for kids
          </a>{' '}
          courses are holistic and our curriculum is scientifically based. Our
          prices are transparent and predictable. Further, we provide money back
          guarantee if you decide to discontinue after enrollment due to any
          reason.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Our coding for kids courses are holistic and our curriculum is scientifically based. Our prices are transparent and predictable. Further, we provide money back guarantee if you decide to discontinue after enrollment due to any reason.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `How can I trust Codingal?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="codingalVsWhitehatJr.faq.answer6">
          Codingal has been founded by leading edtech entrepreneurs, IIT
          graduates, Google & Amazon alumni and also backed by leading investors
          like Y Combinator and Rebright Partners. You can know more about us
          here
        </Trans>{' '}
        -{' '}
        <a href="/about-us/" className="text-orange mr-1 hover:underline">
          https://www.codingal.com/about-us/
        </a>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Codingal has been founded by leading edtech entrepreneurs, IIT graduates, Google & Amazon alumni and also backed by leading investors like Y Combinator and Rebright Partners. You can know more about us here - https://www.codingal.com/about-us/`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What courses does Codingal offer?`,
    }),
    acceptedAnswerText: (
      <>
        <Trans id="codingalVsWhitehatJr.faq.answer7">
          Codingal currently offers live online coding classes for kids. Our
          coding courses include{' '}
          <a
            href="/courses/scratch-programming/"
            className="text-orange mr-1 hover:underline"
          >
            Scratch Programming
          </a>
          ,{' '}
          <a
            href="/courses/python-for-kids/"
            className="text-orange mr-1 hover:underline"
          >
            Python for kids
          </a>
          ,{' '}
          <a
            href="/courses/app-development/"
            className="text-orange mr-1 hover:underline"
          >
            App Development
          </a>
          ,{' '}
          <a
            href="/courses/web-development/"
            className="text-orange mr-1 hover:underline"
          >
            Web Development
          </a>
          ,{' '}
          <a
            href="/courses/game-development/"
            className="text-orange mr-1 hover:underline"
          >
            Game Development
          </a>
          ,{' '}
          <a
            href="/courses/data-science/"
            className="text-orange mr-1 hover:underline"
          >
            Data Science for kids
          </a>
          , and many other courses for Grade 1-12 students from age 5 years to
          age 17 years.
        </Trans>
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Codingal currently offers live online coding classes for kids. Our coding courses include Scratch Programming, Python for kids, App Development, Web Development, Game Development, Data Science for kids, and many other courses for Grade 1-12 students from age 5 years to age 17 years.`,
    }),
  },
]
